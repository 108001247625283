import * as React from "react";

import Page from "../components/Page";
import Container from "../components/Container";
import DefaultLayout from "../layouts/default";
import styled from "styled-components";
import { fonts } from "../styles/variables";
import { Link } from "gatsby";

const StyledTitle = styled.h1`
    font: ${fonts.headline};
    line-height: 1;
    margin-top: 1.7em;
`

const StyledLink = styled(Link)`
  font: ${fonts.text};
  color: black;
`

const IndexPage = () => (
  <DefaultLayout id={""} title={"404"} uri={""} showInNavigation={false}>
    <Page>
      <Container>
        <StyledTitle>Seite nicht gefunden</StyledTitle>
        <StyledLink to="/de">weiter zur Startseite</StyledLink>
        <StyledTitle>Page not found</StyledTitle>
        <StyledLink to="/en">go to homepage</StyledLink>
        <StyledTitle>Page introuvable</StyledTitle>
        <StyledLink to="/fr">aller à la page d'accueil</StyledLink>
        <StyledTitle>Pagina non trovata</StyledTitle>
        <StyledLink to="/it">vai alla homepage</StyledLink>
      </Container>
    </Page>
  </DefaultLayout>
);

export default IndexPage;
